<template>
	<div class="img-alt-container">
		<div>
			<ImageBoxSelector :key="form.url" v-model="form.url" @input="selectedImg" :removable="true" :type="type" />
		</div>
		<p style="line-height:1" class="mt-2 mb-1">Alt Text</p>
		<a-input v-model="form.alt" />

		<div v-if="form.position >= 0" class="mt-3 mb-1">
			<p>
				Image Position For Small Screen
				<a-tooltip overlayClassName="change-tooltip-color">
					<template slot="title">
						With this the image will be positioned such that its specified point will be aligned with the corresponding point in the container. <br>
						Ex. If value is set to 15% then the image's left edge will be positioned 15% of the container's width from the left edge of the container.
                    </template>
					<a-icon type="question-circle-o" style="color: var(--orange);" />
				</a-tooltip>
			</p>
			<a-row>
				<a-col :span="12">
					<a-slider v-model="form.position" :min="1" :max="100" />
				</a-col>
				<a-col :span="4">
					<a-input-number v-model="form.position" :min="1" :max="100" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" class="ml-3"/>
				</a-col>
			</a-row>
		</div>
	</div>

</template>

<script>
	import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
	import mime from 'mime-types'

	export default {
		components: { ImageBoxSelector },
		props: {
			value: {
				type: Object,
				required: true,
				default: () => { }
			},
			type: {
				type: String,
				default: 'images'
			},
		},
		data() {
			return {
				form: {
					url: '',
					sm: '',
					md: '',
					lg: '',
					width: 0,
					height: 0,
					lazy: '',
					position: 50,
				},
				images: this.value,
				loading: false,
				fileType: mime.lookup(this.value)
			}
		},
		watch: {
			form: {
				deep: true,
				handler(val, oldVal) {
					return this.$emit('input', val)
				}
			}
		},
		methods: {
			removeImage(imgI) {
				console.log('REMOVING IMAGE', imgI)
				return this.images = this.images.filter((x, xI) => xI !== imgI)
			},
			async handleChange(info) {

				console.log('INFO FILE', info.file)

				this.loading = true
				let formData = new FormData()
				formData.append('files', info.file, info.file.name);
				formData.append('path', this.$bhapp);
				formData.append('instance', this.$store.state.instance.id);

				let url = `/upload`;
				this.$api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
					this.loading = false
					data = data[0]
					if (data.url) {
						this.img = data.url
					} else {
						this.$message.error('Oops. Something went wrong.')
					}
				}).catch(err => {
					this.loading = false
					this.$message.error(this.$err(err))
				})
			},
			beforeUpload(file) {
				this.loading = true
				const isJPG = file.type === 'image/jpeg';
				const isLtM = file.size / 1024 / 1024 < 5;
				if (!isLtM) {
					this.$message.error('Image must smaller than 5MB!');
					return false
				}
				return false;
			},
			selectedImg(item) {
				console.log('SELECTED IMG ITEM', item)
				if (typeof item === 'string') item = { url: item }

				let img = document.createElement('img');

				let self = this

				let ext = item.url.split('.')[item.url.split('.').length - 1]
				let retry = false
				img.onerror = (err) => {
					console.log('ERRERRERERER', err)
					this.form = {
						url: item.url,
						width: 0,
						height: 0,
						lazy: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAbUlEQVQYV2NsaU8OFXU128B2mY05MTHxR35KpB5jy8zA/8oG0qYc14zvBCYmfshNiX7KOGm353+Gfwx+uW6TdjEyqv5kYGBggAuysXE9//HnqzDD/78rGCfucPfgYP57nJVNhO/D9/fsLL/4pAFlTinHVpoEhgAAAABJRU5ErkJggg==',
						sm: item.formats && item.formats.sm ? item.formats.sm : '',
						md: item.formats && item.formats.md ? item.formats.md : '',
						lg: item.formats && item.formats.lg ? item.formats.lg : '',
						alt: '',
					}
				}

				img.onload = () => {

					console.log('width => ', img.naturalWidth);
					console.log('height => ', img.naturalHeight);
					let width = img.naturalWidth
					let height = img.naturalHeight

					if (ext !== 'svg' && (!width || !height)) {

						return self.$message.error('Image Error. Not a Valid Image')

					} else {

						let canv = document.createElement('canvas')
						let ctx = canv.getContext('2d')

						canv.width = 5
						canv.height = 5

						ctx.drawImage(img, 0, 0, 5, 5)

						let lazy = canv.toDataURL()
						console.log('SETTING LAZY', lazy)

						this.form = {
							url: item.url,
							width,
							height,
							sm: item.formats && item.formats.sm ? item.formats.sm : '',
							md: item.formats && item.formats.md ? item.formats.md : '',
							lg: item.formats && item.formats.lg ? item.formats.lg : '',
							lazy,
							alt: '',
						}

					}

				}
				img.crossOrigin = "Anonymous"
				img.src = item.url.replace('bildhive.nyc3.digitaloceanspaces.com', 'bildhive.nyc3.digitaloceanspaces.com')

				console.log('THIS TYPE', this.type);
			},
			selectMedia() {
				this.$store.commit('MEDIA_SELECT', { callback: this.selectedImg, type: this.type })
			},
			fromLibrary() {
				this.$store.commit('LIBRARY_SELECT', { callback: this.selectedImg, type: 'icons' })
			},
		},
		created() {
			let value = this.value
			if (!value || !value.url) value = { url: '', alt: '' }
			this.form = value
		},
		mounted() {
			setTimeout(() => {
				let self = this

				let dom = this.$el.querySelector('.bh-file-selector input[type=file]')
				if (!dom) return
				delete dom.onclick
				dom.onclick = e => {
					e.preventDefault()
					self.selectMedia()
				}
			}, 1)
		}
	}
</script>

<style scoped lang="scss">
	.image-holder-box {
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		width: 100px;
		height: 100px;
	}

	.img-alt-container {
		display: grid;
		padding: 10px;
		border: 1px solid #eee;

	}

	.img-alt-box {
		width: 100px;
		height: 100px;
		background-size: cover;
		border-radius: 4px;
		position: relative;
		background-color: #ccc;
		background-position: center;
		border: 1px dashed #ccc;
		box-shadow: 0 2px 5px rgba(100, 100, 100, 0.2);
		position: relative;

		&:hover:after {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			content: '';
			background: rgba(100, 100, 100, 0.2);
			z-index: 1;
		}
	}
</style>
